.main {
    max-width: 100%;
    padding-top: map_get($header-height, "small");
    transition: $transition-default;
    height: 100%;
    //flex: 1;
    z-index: 1;
    position: relative;
    min-height: calc(100vh - 80px);

    @include media-breakpoint-up(md) {
        padding-top: #{40px + map_get($header-height, "medium")};
    }

    @include media-breakpoint-up(lg) {
        padding-top: #{40px + map_get($header-height, "large")};
    }
}