.warehouse {
    &__colors-wrapper {
        overflow: visible;
        overflow-x: hidden;
        position: relative;
        min-height: 30vh;

        @include media-breakpoint-up(md) {
            min-height: 290px;
        }
    }

    &__title {
        font-size: rem-calc(35);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(55);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(65);
        }
    }

    &__image {
        margin-top: -5%;
        width: 100%;
        z-index: -1;
        position: relative;
    }

    &__colors-text {
        @include media-breakpoint-up(md) {
            padding-right: 40px;
        }
    }

    &__colors {
        width: 100%;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
            left: 50%;
            bottom: 0;
        }
    }

    &__color {
        width: 30%;
        height: 30vh;
        display: inline-flex;
        align-items: flex-end;
        padding: 10px;

        // dit zorgt er voor dat er geen witruimtes van halve pixels te zien zijn tussen de slides

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background: inherit;
            left: -2px;
            right: -2px;
            top: 0;
            bottom: 0;
        }

        @include media-breakpoint-up(md) {
            width: 215px;
            height: 290px;
        }

        /* stylelint-disable */
        &--1015 {
            background-color: #F6E1AA;
            color: map-deep-get($colors, "black", "default");
        }

        &--3009 {
            background-color: #641707;
            color: map-deep-get($colors, "white", "default");
        }

        &--3016 {
            background-color: #9E0B01;
            color: map-deep-get($colors, "white", "default");
        }

        &--5010 {
            background-color: #002B70;
            color: map-deep-get($colors, "white", "default");
        }

        &--6009 {
            background-color: #17291D;
            color: map-deep-get($colors, "white", "default");
        }

        &--6011 {
            background-color: #5E7E4F;
            color: map-deep-get($colors, "white", "default");
        }

        &--7016 {
            background-color: #364035;
            color: map-deep-get($colors, "white", "default");
        }

        &--7035 {
            background-color: #B2BBB6;
            color: map-deep-get($colors, "black", "default");
        }

        &--8014 {
            background-color: #231F16;
            color: map-deep-get($colors, "white", "default");
        }

        &--9002 {
            background-color: #D6D9C8;
            color: map-deep-get($colors, "black", "default");
        }

        &--9006 {
            background-color: #B5B3B4;
            color: map-deep-get($colors, "black", "default");
        }

        &--9005 {
            background-color: #0e0e10;
            color: map-deep-get($colors, "white", "default");
        }

        &--9007 {
            background-color: #878581;
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }
}
