.warehouses-item {
    position: relative;
    overflow: hidden;
    margin-bottom: $grid-gutter-width;
    padding-bottom: 17.5%;

    $elm: &;

    &__image {
        width: 100%;
        vertical-align: middle;
    }

    &__overlay {
        border: 1px solid map-deep-get($colors, "gray", "default");
        bottom: 0;
        color: map-deep-get($colors, "white", "default");
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-font-smoothing: antialiased;

        &::before {
            transition: $transition-default;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.33) 0%, map-deep-get($colors, "brand", "secondary") 100%);
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 1;

            @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
                opacity: 0;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        align-items: flex-end;
        display: flex;
        height: 100%;
        padding: 10px;
        width: 100%;

        svg {
            width: 30px;
            height: 20px;
            fill: map-deep-get($colors, "brand", "primary");
            margin-right: 5px;
            vertical-align: text-top;
        }
    }

    &__link-wrapper {
        transition: $transition-default;
        flex: 1;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            overflow: hidden;
            opacity: 0;
            max-height: 0;
        }
    }

    &__link {
        transition: $transition-default;
        text-decoration: underline;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        font-size: rem-calc(26);
        transition: $transition-default;
        display: inline-block;

        @media (pointer: coarse) {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &:hover {
        #{$elm}__title {
            color: map-deep-get($colors, "white", "default");
            margin-bottom: 1em;
        }

        #{$elm}__link-wrapper {
            opacity: 1;
            max-height: 3em;
        }

        #{$elm}__overlay::before {
            opacity: 1;
        }
    }
}