.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    border-bottom: 1px solid transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    overflow: hidden;
    $header: &;

    @include media-breakpoint-up(md) {
        margin: 20px 0 0 auto;
        height: map_get($header-height, "medium");
        overflow: initial;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--scrolled {
        background: map-deep-get($colors, "white", "default");
        border-bottom-color: map-deep-get($colors, "brand", "secondary");
        margin-top: 0;

        @include media-breakpoint-up(md) {
            #{$header}__logo {
                height: #{map_get($header-height, "medium--scrolled") - 16px};
            }
        }

        @include media-breakpoint-up(xl) {
            #{$header}__logo {
                height: map_get($header-height, "medium--scrolled");
            }
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        transition: $transition-default;
        height: map_get($header-height, "small");
        padding-top: 8px;
        padding-bottom: 8px;
        display: block;

        @include media-breakpoint-up(md) {
            height: #{map_get($header-height, "medium") - 16px};
        }

        @include media-breakpoint-up(xl) {
            height: map_get($header-height, "large");
        }

        svg {
            height: 100%;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
    }

    //css voor logo
    /* stylelint-disable */
    .cls-1{fill:#8aac32;}
    .cls-2{fill:#004566;}
    .cls-3{fill:none;}
    /* stylelint-enable */
}