.partners {
    background: map-deep-get($colors, "gray", "light");
    z-index: 0;
    position: relative;
    padding: 75px 15px;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding: 100px 0;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 130px;
        padding-bottom: 0;
    }

    &__title {
        margin-bottom: 0;
        transform: translateY(65%);
        z-index: 1;
        position: relative;
    }

    &__subtitle {
        color: rgba(map-deep-get($colors, "brand", "secondary"), 0.1);
        font-size: 70px;
        font-weight: 900;
        z-index: -1;
        position: relative;
        margin-bottom: -0.35em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(80);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(100);
        }
    }

    &__content {
        @include media-breakpoint-up(lg) {
            padding-bottom: 150px;
        }
    }

    &__items {
        margin-bottom: -#{65px + 135px};
        margin-top: 3.125rem;

        @include media-breakpoint-up(xl) {
            margin-bottom: -#{80px + 135px};
        }
    }
}