.dealers {
    &__heading {
        text-align: center;
        margin-bottom: 40%;

        @include media-breakpoint-up(lg) {
            margin-bottom: 12.5%;
        }
    }

    &__title {
        @extend .h3;
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: 0.25em;
    }
}